'use strict';


var Song = require('./Song');

function PlayerUI(parent_el, options, data) {
	this.el = parent_el;
	this.options = {
		show_all: options.show_all || false,
		download: options.download || false
	};
	
	this.list_ul = this.el.querySelector('[data-playlist-container]');
	this.songs_div = this.el.querySelector('[data-song-container]');
	
	this.picture_frame = document.getElementById('picture_frame');
	this.picture_frame_img = this.picture_frame.querySelector('img');
	
	this.close_picture_frame = this.picture_frame.querySelector('[data-close-modal="picture_frame"]');
	this.close_picture_frame.addEventListener('click', function(e){
		this.picture_frame.classList.remove('visible');
	}.bind(this));
	
	this.audio_folder = data.audio_folder;
	this.playlists = data.playlists;

	this.cur_list = 0;
	this.cur_song = [];
}

var proto = PlayerUI.prototype;



// ••••••••••
// PUBLIC METHODS
// ••••••••••

proto.populateLists = function() {
	this.list_ul.innerHTML = '';
	
	for(var x=0, l=this.playlists.length; x<l; x++) {
		var list = this.playlists[x];
		var li = this._getListLi(list, x);
		this.list_ul.appendChild(li);
		
		this.populateSongs(x);
	}
};



proto.populateSongs = function(list_idx) {
	var div = document.createElement('div');
	div.classList.add('playlist');
	div.setAttribute('data-playlist', list_idx);
	
	if(this.cur_list===list_idx) {
		div.classList.add('selected');
	}
	
	var ul = document.createElement('ul');
	div.appendChild(ul);
	
	var playlist = this.playlists[list_idx];
	var song_list = playlist.songs;
	
	for(var x=0, l=song_list.length; x<l; x++) {
		var song = song_list[x];
		
		if(this.options.show_all || song.make_public){
			var li = this._getSongLi(song, x);
			ul.appendChild(li);
		}
	}
	
	var listExtras = this._getListExtras(playlist);
	if (listExtras) { div.appendChild(listExtras); }
	
	this.songs_div.appendChild(div);
};


// ••••••••••
// PRIVATE METHODS
// ••••••••••

proto._advancePlaylist = function(){
	if (this.cur_song[1] < this.playlists[this.cur_song[0]].songs.length-1) {
		this.cur_song[1]++;
		
		var song = this.playlists[this.cur_song[0]].songs[this.cur_song[1]];
		
		if(this.options.show_all || song.make_public) {
			return true;
		} else {
			return this._advancePlaylist();
		}
		
	} else {
		return false;
	}
};



proto._clearClasses = function(selector, classArray) {
	var els = document.querySelectorAll(selector);
	for(var x=0, l=els.length; x<l; x++){
		for(var i=0, len=classArray.length; i<len; i++) {
			els[x].classList.remove(classArray[i]);
		}
	}
};



proto.getDownloadLink = function(folder, zipfile) {
	var a = document.createElement('a');
	a.setAttribute('href', '/audio/'+folder+'/'+zipfile);
	a.setAttribute('class', 'icon-download');
	a.setAttribute('download', zipfile);
	
	var label = document.createTextNode('Download');
	a.appendChild(label);
	
	return a;
};



proto.getProgramLink = function(link) {
	var a = document.createElement('a');
	a.setAttribute('href', '/programs/'+link);
	a.setAttribute('class', 'icon-document-blank');
	
	var label = document.createTextNode('Program');
	a.appendChild(label);
	
	return a;
};



proto.getPhotoLink = function(photo) {
	var a = document.createElement('a');
	a.setAttribute('href', '#');
	a.setAttribute('class', 'icon-picture');
	a.setAttribute('data-show-photo', '/portraits/'+photo);
	
	a.addEventListener('click', function(e){
		e.preventDefault();
		
		this.picture_frame_img.src = '/imgs/portraits/'+ photo;
		this.picture_frame.classList.add('visible');
	}.bind(this));
	
	var label = document.createTextNode('Photo');
	a.appendChild(label);
	
	return a;
};

proto.getVideoLink = function(video) {
	var a = document.createElement('a');
	a.setAttribute('href', video);
	a.setAttribute('class', 'icon-video');
	
	var label = document.createTextNode('Video');
	a.appendChild(label);
	
	return a;
};



proto._getListExtras = function(list) {
	
	var tests = {
		program: !!list.program,
		photo: !!list.photo,
		video: !!list.video,
		invitation: !!list.invitation,
		download: this.options.download && !!list.download
	};
	
	if (this._testConditions(tests)) {
	
		var div = document.createElement('div');
		div.setAttribute('class', 'playlist_extras');
		
		if(tests.program){ div.appendChild( this.getProgramLink(list.program) ); }
		
		if(tests.photo){ div.appendChild( this.getPhotoLink(list.photo) ); }
		
		if(tests.video){ div.appendChild( this.getVideoLink(list.video) ); }
		
		if(tests.download){ div.appendChild( this.getDownloadLink(list.folder, list.download) ); }
		
		return div;
		
	} else {
		return null;
	}
};



proto._getListLi = function(list, idx) {
	var li = document.createElement('li');
	li.setAttribute('data-playlist-trigger', idx);
	
	if(idx==this.cur_list){
		li.classList.add('selected');
	}
	
	var span = document.createElement('span');
	var txt = document.createTextNode(list.playlist_name);
	span.appendChild(txt);
	li.appendChild(span);
	
	var parent = this;
	
	li.addEventListener('click', function(e) {
		parent._listBtnClick(this, parent);
	});
	
	return li;
};



proto._getSongLi = function(song, idx) {
	var li = document.createElement('li');
	li.setAttribute('data-song', idx);
	
	var span = document.createElement('span');
	var txt = document.createTextNode(song.title);
	var div = document.createElement('div');
	div.classList.add('progress_bar');
	span.appendChild(txt);
	li.appendChild(span);
	li.appendChild(div);
	
	var parent = this;
	
	li.addEventListener('click', function(e){
		parent._songBtnClick(this, parent);
	});
	
	return li;
};



proto._listBtnClick = function(btn, parent) {
	parent._clearClasses('[data-playlist-trigger]', ['selected']);
	parent._clearClasses('.playlist', ['selected']);
	
	parent.cur_list = btn.getAttribute('data-playlist-trigger');

	btn.classList.add('selected');
	document.querySelector('.playlist[data-playlist="'+ parent.cur_list +'"]').classList.add('selected');
};



proto._songAnalytics = function(song_coord, action){
	if(window.ga) {
		ga('send', {
			hitType: 'event',
			eventCategory: 'Music Player',
			eventAction: action,
			eventLabel: this.playlists[song_coord[0]].songs[song_coord[1]].title,
			eventValue: song_coord[0]
		});
	}
};



proto._songBtnClick = function(btn, parent) {
	var idx = btn.getAttribute('data-song');
	var progress_bar = btn.querySelector('.progress_bar');
	var playlist = parent.playlists[parent.cur_list];
	var song = playlist.songs[idx];
	
	if(song.audio === undefined){
		var path = this.audio_folder +'/'+ playlist.folder +'/'+ song.filename;
		song.audio = new Song(path);
	}
	
	if(btn.classList.contains('paused')){ // unpause song
		
		song.audio.play();
		btn.classList.remove('paused');
		this._updatePlayhead(progress_bar, song.audio);
		
	} else {
		if(btn.classList.contains('playing')){ // pause song
			
			song.audio.pause();
			btn.classList.add('paused');
			cancelAnimationFrame(this.animator);
			
		} else { // play new song
			
			if(parent.cur_song.length>1){
				parent.playlists[this.cur_song[0]].songs[this.cur_song[1]].audio.stop();
				cancelAnimationFrame(this.animator);
			}
			
			parent._clearClasses('[data-song]', ['playing', 'paused']);
			
			parent.cur_song = [parent.cur_list, idx];
			
			song.audio.play();
			
			this._songAnalytics(parent.cur_song, 'play');
			
			btn.classList.add('playing');
			this._updatePlayhead(progress_bar, song.audio);
		}
	}
}



proto._songEnded = function() {
	this._songAnalytics(this.cur_song, 'complete');
	
	this._clearClasses('[data-song]', ['playing', 'paused']);
	
	if (this._advancePlaylist()) {
		this._songBtnClick(this.songs_div.querySelector('[data-playlist="'+ this.cur_song[0] +'"] [data-song="'+ this.cur_song[1] +'"]'), this);
	}
};



proto._testConditions = function(list) {
	var test = false;
	
	while (test==false) {
		for (var cond in list) {
			if (list[cond]) {
				test = true;
			}
		}
		break;
	}
	
	return test;
};



proto._updatePlayhead = function(el, song) {
	if(song.ended()) {
		cancelAnimationFrame(this.animator);
		this._songEnded();
	} else {
		el.style.width = song.getPercentPlayed() + '%';
		
		this.animator = requestAnimationFrame(function(){
			this._updatePlayhead(el, song);
		}.bind(this));
	
	}
};



module.exports = PlayerUI;




